import moment from "moment";

export const createQueryLogRequest = (filterParams, items, childRequest) => {
  const { showEmptyResults, searchFor, showAlerted, showVerified, showFlagged, datesRange } =
    filterParams;

  const [startDate, endDate] = datesRange;

  const request = {
    hideEmptyResults: !showEmptyResults,
    searchFor,
    startDate: moment(startDate).startOf("day").toISOString(),
    endDate: moment(endDate).endOf("day").toISOString(),
    perPage: childRequest ? childRequest.itemsPerPage : 10,
    skipNumber: childRequest ? childRequest.page - 1 : 0,
    sortBy: childRequest?.sortBy[0] || "time",
    sortDesc: childRequest && childRequest.sortDesc[0] ? 1 : -1,
    showAlerted,
    showVerified,
    showFlagged,
  };

  return request;
};

export default {
  createQueryLogRequest,
};
