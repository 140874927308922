<template>
    <v-container fluid>
        <v-row class="mb-3 pl-1">
        <v-col cols="12">
            <v-btn @click="showData = !showData" class="mt-1">
            <v-icon v-if="!showData" style="opacity: 0.3;" class="pr-2">visibility</v-icon> 
            <v-icon v-else style="opacity: 0.3;" class="pr-2">visibility_off</v-icon> 
            Additional allerts
            </v-btn>
        </v-col>
        </v-row>
        <Transition>
        <v-row v-if="showData" class="mb-3 pl-1 extra_tables">
        <v-col class="v-col-12">
            <v-data-table
            :headers="headers"
            :items="items"
            :hide-default-footer="true">
            <template #item.value="{item}">
                <div class="sc_data">
                <span v-html="item.value"></span>
                </div>
            </template>

            <template #item.type="{item}">
                <div class="sc_data">
                <span v-html="buildItemTypeWithLinks(item.type)"></span>
                </div>
            </template>

            </v-data-table>
            <hr><br>
        </v-col>
        </v-row>
        </Transition>
    
    </v-container>
</template>

<script>
export default { 
    props: {
        extra_fields: Array,
        transactionFullData: Object,
    },
    data() {
        return {
            showData: true,
            extraData: [],
            extraTablesData: [],
            headers: [
                {
                    text: 'FIELD',
                    value: 'paymentSegmentField'
                },
                {
                    text: 'VALUE',
                    value: 'value'
                },
                {
                    text: 'CONTEXT',
                    value: 'searched'
                },
                {
                    text: 'TYPE',
                    value: 'type'
                },
            ],
            items: [],
        };
    },
    methods: {
        buildItemTypeWithLinks(dataType) {
            const dataLinkItems = {
                'link1': `<a target="_blank" href="https://www.bis.doc.gov/index.php/regulations/export-administration-regulations-ear">[1]</a><a target="_blank" href="https://www.bis.doc.gov/index.php/documents/regulation-docs/418-part-744-control-policy-end-user-and-end-use-based/file">[2]</a>`,
                'link2': `<a target="_blank" href="https://www.govinfo.gov/app/details/CFR-2012-title22-vol1/CFR-2012-title22-vol1-sec126-1">[1]</a><a target="_blank" href="https://www.govinfo.gov/content/pkg/CFR-2012-title22-vol1/xml/CFR-2012-title22-vol1-sec126-1.xml">[2]</a>`,
                'link3': `<a target="_blank" href="https://ofac.treasury.gov/sanctions-programs-and-country-information">[1]</a>`,
            };
            const dataLinks = {
                'EmbargoEAR': dataLinkItems.link1,
                'TargetedEAR': dataLinkItems.link1,
                'MilitaryEAR': dataLinkItems.link1,
                'ProhibitedITAR': dataLinkItems.link2,
                'ArmsITAR': dataLinkItems.link2,
                'SpecialITAR': dataLinkItems.link2,
                'EmbargoOFAC': dataLinkItems.link3,
                'TargetedOFAC': dataLinkItems.link3,
            };

            if (dataType) {
                let dataTypeArray = dataType.split(',');
                const dataTypeArrayLinked = dataTypeArray.map((dataline) => {
                return `${dataline} ${dataLinks[dataline.trim()]}`;
                });
                return dataTypeArrayLinked.join(', ');
            }
            else {
                return '';
            }
        },
        objToArray(rowObj) {
            let rowStr = '';
            for (const [key, value] of Object.entries(rowObj)) {
                rowStr += `${key}: <span class="extraValue">${value}</span>      `;
            }
            return rowStr;
        },
        getFromXML(key_path) {
            const path_arr = key_path.split('.');
            let payloadString = '';

            if (this.transactionFullData && this.transactionFullData.Payload) {
                payloadString = this.transactionFullData.Payload;
            }

            if (payloadString && path_arr.length) {
                path_arr.forEach((item_path) => {
                payloadString = payloadString.substring(payloadString.indexOf(`<${item_path}>`) + item_path.length + 2, payloadString.lastIndexOf(`</${item_path}>`));
                });
            }

            payloadString = payloadString.replaceAll(`<${path_arr[path_arr.length - 1]}>`, '');
            payloadString = payloadString.replaceAll(`</${path_arr[path_arr.length - 1]}>`, ', ');

            return payloadString;
        },
        sanitizeObj(ObjOrArray) {
            let sanitized = [];
            let output = '';
            if (Array.isArray(ObjOrArray)) {
                sanitized = ObjOrArray;
            } else {
                sanitized = Object.values(ObjOrArray);
            }

            sanitized.forEach((textline) => {
                if (textline.indexOf(', context:') > 0) {
                    textline = textline.substring(0, textline.indexOf(', context:'));
                }
                output += '<li>' + textline.replaceAll('\r\n','<br>') + '</li>';
            })

            return '<ul>'+ output + '</ul>';
        },
        buildExtraTable() {
            const rowData = this.extra_fields;

            for (let ind = 0; ind < rowData.length; ind++) {
                Object.keys(rowData[ind]).forEach((single_item) => {

                    if (single_item == 'paymentSegmentField') {
                        let searched = this.getFromXML(rowData[ind][single_item]);
                        if (searched) {
                            rowData[ind].searched = searched;
                        }
                    }

                    if(typeof rowData[ind][single_item] != 'string') {
                        rowData[ind][single_item] = this.sanitizeObj(rowData[ind][single_item]);
                    } 
                    else {
                        if (single_item == 'value') {
                            rowData[ind][single_item] = this.sanitizeObj([rowData[ind][single_item]]);
                        }
                    }
                });
                this.items.push(rowData[ind]);
            } // for ends
        }
    },
    created() {
        if (this.extra_fields.length) {
            this.buildExtraTable();
        }
    },
};
</script>

<style>
    .extraValue{
        display: inline-block;
        color: #666; /* #161A4F; */
        margin-right:20px;
    }

    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }

    .extra_tables thead.v-data-table-header {
        background: #dfdfdf;
    }

    div.sc_data {
        max-height: 200px;
        overflow: auto;
    }

    ul{
        list-style-type: none;
    }
</style>
