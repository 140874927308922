<template>
  <v-form
    @submit.prevent="loading=true; $emit('submit', [data.selectedQuerylogs, approveReason, submittedfiles])"
  >
    <v-card>
      <v-card-title> Are you sure you want to approve querylogs? </v-card-title>
      <v-card-text>
        <v-text-field label="The reason for approving" v-model="approveReason" />
      </v-card-text>
      <file-input-multiple @getFiles="getFiles" @noFiles="noFiles"></file-input-multiple>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="primary" type="submit" :loading="loading" :disabled="approveReason.length < 5 || loading"> Submit </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import fileInputMultiple from "./file-input-multiple.vue";
export default {
  components: { fileInputMultiple },
  data() {
    return {
      loading: false,
      approveReason: "",
      submittedfiles: [],
    };
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        selectedQuerylogs: [],
      }),
    },
  },
  methods: {
    noFiles() {
      this.submittedfiles = [];
      this.$emit("noFiles");
    },
    getFiles(files) {
      this.submittedfiles = files;
    },
  },
};
</script>

<style scoped>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
