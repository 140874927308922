<template>
  <v-container fluid v-if="data">
    <QueryLogDetailsHeader :item="data" />
    <QueryLogDetailsTable :item="data" />
    <!-- <QueryLogItemHistory :history="data.history" class="mt-5" /> -->
  </v-container>
</template>

<script>
import QueryLogDetailsHeader from "@/components/query-log/logs-details/QueryLogDetailsHeader";
import QueryLogDetailsTable from "@/components/query-log/logs-details/QueryLogDetailsTable";

export default {
  components: {
    QueryLogDetailsHeader,
    QueryLogDetailsTable,
  },
  props: {
    id: String,
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    async loadData() {
      this.data = (await this.$apiClient.getQueryLogDetails(this.$route.params.id)).data;
      this.data._id = this.data.id;
    },
  },
  created() {
    if (!this.data && this.id) {
      this.loadData();
    }
  },
};
</script>
