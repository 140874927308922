<template>
  <v-container fluid>
    <v-row class="mb-3 pl-1">
      <h3 class="page-title">Query details</h3>
    </v-row>
    <v-row class="mb-5 pl-1">
      Searched for: <span class="searched_name">{{ getSearchedNames() }}</span>
    </v-row>
    <v-row class="mb-3">
      <v-text-field
        disabled
        readonly
        class="ml-1 mr-1"
        label="Query ID"
        :value="getId()"
      ></v-text-field>

      <v-text-field
        disabled
        readonly
        class="ml-1 mr-1"
        label="Query at"
        :value="fmt(item.time)"
      ></v-text-field>

      <v-text-field
        disabled
        readonly
        class="ml-1 mr-1"
        label="Last update:"
        :value="fmt(item.updatedAt)"
      >
      </v-text-field>
    </v-row >
        
    <v-row>
        <v-text-field
          v-if="miscId"          
          class="ml-1 mr-1"
          label="MID:"
          :value="miscId"
        />

        <v-text-field
          v-if="externalIdRef"
          readonly
          class="ml-1 mr-1"
          label="ExtRef:"
          :value="externalIdRef"
        />
        <v-text-field
          v-if="transactionAmount"      
          readonly
          class="ml-1 mr-1"
          label="Amount:"
          :value="transactionAmount"
        />
    </v-row>

    <v-row class="mb-5">
      <v-toolbar flat>
        <v-btn v-if="isUserSystemAdmin" @click="toggle">
          {{ rawDataButtonCaption }}
        </v-btn>
        <a @click="$router.go(-1)" class="ml-4">All Queries</a>
        <v-spacer />

        <UpdateStatusToolbar
          v-if="this.$store.getters['users/actionRoles'].includes(userRole)"
          v-model="item.status"
          @input="updateStatus"
        />
        <SubmitSingleButton
          v-if="this.$store.getters['users/actionRoles'].includes(userRole) && item.finalStatus.toLowerCase() !== 'submitted'"
          :is-submit-disabled="item.status === 'alerted'"
          @close="closeModal($event)"
          :item="item"
          @submit="submitSingle($event)"
        />
        <SubmitApproveButton
          v-if="['CHIEF_SUPERVISOR', 'SUPERVISOR'].includes(userRole) && item.finalStatus.toLowerCase() === 'submitted'"
          :is-submit-disabled="false"
          @close="closeModal($event)"
          :item="item"
          @submit="approveSingle($event)"
        />
        <SubmitReopenButton
          v-if="userRole === 'SUPERVISOR' && item.finalStatus.toLowerCase() === 'submitted'"
          :is-submit-disabled="false"
          @close="closeModal($event)"
          :item="item"
          @submit="reopenSingle($event)"
        />
      </v-toolbar>
    </v-row>

    <v-row>
      <VueJsonPretty :data="item" :deep="2" v-if="this.showRawData" />
    </v-row>

    <v-row v-if="item.sanctionedItemsSearch && item.sanctionedItemsSearch.length">
      <extra-fields 
        :extra_fields="item.sanctionedItemsSearch"
        :transactionFullData ="item.incomingTransactionFullData ? item.incomingTransactionFullData : null"
        ></extra-fields>
    </v-row>
    
    <!-- displays a pop up message on the bottom of the screen -->
    <UpdateStatusNotification
      v-model="updateStatusRequested"
      :isUpdateSuccess="isUpdateStatusSuccess"
      :message='infoMessage'
      :color = 'messageColor'
      :toURL = 'toURL'
      :timeDelay = timeDelay
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VueJsonPretty from "vue-json-pretty";
import shared from '@/utils/shared'

import { QUERY_LOG_UPDATE_REQUEST } from "@/store/actions/queryLog";
//import SearchedQuery from "./SearchedQuery";
import { createSubmitQuerylogsRequest } from "@/helpers/supervision";
import { createApproveQuerylogsRequest } from "@/helpers/supervision";
import { createReopenQuerylogsRequest } from "@/helpers/supervision";
import { QUERYLOG_UPDATE_REQUEST } from "@/store/actions/supervision";
import UpdateStatusToolbar from "../toolbars/UpdateStatus";
import SubmitSingleButton from "../toolbars/SubmitButton";
import SubmitApproveButton from "../toolbars/ApproveButton";
import SubmitReopenButton from "../toolbars/ReopenButton";
import UpdateStatusNotification from "../UpdateStatusNotification";
import { format } from "@/utils/dates";
import ExtraFields from './ExtraFields';

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      timeDelay: 3000,
      sanctionedItemsSearch: [
        { type: 'vessel', value: 'aaj', paymentSegmentField: 'Rfb' },
        { type: 'country', value: 'north korea', paymentSegmentField: 'Obi' },
        { type: 'country', value: 'iran', paymentSegmentField: 'Bbi' }
        ],
      showRawData: false,
      updateStatusRequested: false,
      showMenu: false,
      miscId: '',
      externalIdRef: '',
      transactionAmount: '',
      infoMessage: '',
      messageColor: 'success',
      toURL: ''
    };
  },
  components: {
    UpdateStatusToolbar,
    SubmitSingleButton,
    SubmitApproveButton,
    SubmitReopenButton,
    VueJsonPretty,
    UpdateStatusNotification,
    ExtraFields
  },
  computed: {
    ...mapGetters({
      userRole: "userRole",
      updateStatusResult: "queryLog/status",
    }),
    rawDataButtonCaption() {
      return this.showRawData ? "Hide raw query" : "Show raw query";
    },
    isUpdateStatusSuccess() {
      return this.updateStatusResult === "success";
    },
  },
  methods: {
    ...mapActions({
      updateItem: `queryLog/${QUERY_LOG_UPDATE_REQUEST}`,
      updateQuerylog: `supervision/${QUERYLOG_UPDATE_REQUEST}`,
    }),
    isUserSystemAdmin() {
      return this.userRole === "SYS_ADMIN";
    },
    async updateStatus(status) {
      const resp = await this.updateItem({ items: [this.item], status });
      this.displayInfo(resp, 'updateStatus');
    },
    toggle() {
      this.showRawData = !this.showRawData;
    },
    getId() {
      return this.item.id || this.item._id;
    },
    getSearchedNames() {
      const searchedNamesArr = [];

      this.item.searchedFields.forEach((searchedField) => {
        if (!searchedNamesArr.includes(searchedField.searchedName)) {
          searchedNamesArr.push(searchedField.searchedName);
        }
      });

      return searchedNamesArr.join(" ,");
    },
    fmt(time) {
      return format(time);
    },
    // linkToList() {
    // TODO : (maybe) buid whole URL accordin to user role (admin has a different URL)
    // for now we use back button
    //   return `/supervision/dashboard/${this.userRole.toLowerCase()}`;
    // },
    submitSingle($event) {
      let dataToSave = JSON.parse($event);
      dataToSave.item[0]._id = dataToSave.item[0].id;
      this.submitQuerylogs(dataToSave.item, dataToSave.reason, dataToSave.files);
    },
    approveSingle($event) {
      let dataToApprove = JSON.parse($event);
      dataToApprove.item[0]._id = dataToApprove.item[0].id;
      this.approveQuerylogs(dataToApprove.item, dataToApprove.reason, dataToApprove.files);
    },
    reopenSingle($event) {
      let dataToReopen = JSON.parse($event);
      dataToReopen.item[0]._id = dataToReopen.item[0].id;
      this.reopenQuerylogs(dataToReopen.item, dataToReopen.reason, dataToReopen.files);
    },
    async submitQuerylogs(selectedQuerylogs, selectedReason, files) {
      const request = createSubmitQuerylogsRequest(selectedQuerylogs, selectedReason, files);
      await this.updateQuerylog(request);
      this.$router.push(this.prepareInnerUrl());
    },
    async approveQuerylogs(selectedQuerylogs, selectedReason, files) {
      const request = createApproveQuerylogsRequest(selectedQuerylogs, selectedReason, files);
      const resp = await this.updateQuerylog(request, selectedReason);
      this.displayInfo(resp, 'approveQuerylogs');
      // this.$router.push(this.prepareInnerUrl());
    },
    async reopenQuerylogs(selectedQuerylogs, selectedReason, files) {
      const request = createReopenQuerylogsRequest(selectedQuerylogs, selectedReason, files);
      await this.updateQuerylog(request, selectedReason);
      this.$router.push(this.prepareInnerUrl());
    },
    returnToDashboard(){
      setTimeout(() => {
        this.$emit('input', false);
        this.$router.push(this.prepareInnerUrl());
      }, this.timeDelay);
    },
    displayInfo(dataToShow, customEvent) {
      let updatedData = '' 
      
      if (customEvent == 'approveQuerylogs') {
        if (!dataToShow) {
          this.messageColor = 'error';
          updatedData = 'Error: Cannot connect to API server';
        } else {
          dataToShow.forEach((queryData) => { 
            if (queryData.status.toLowerCase() === 'error') {
              this.messageColor = queryData.status.toLowerCase();
              updatedData += queryData.status + ': ' + queryData.message + '\n\r';
            } else {
              this.messageColor = 'success';
              updatedData += queryData.id + ': ' + queryData.finalStatus + '\n\r';
            }
          });
        }
        this.updateStatusRequested = true;
        this.returnToDashboard();
      } else if (customEvent == 'updateStatus') {
        this.updateStatusRequested = true;
        this.messageColor = dataToShow.status.toLowerCase();
        if (this.messageColor === 'error') {
          this.revertStatusDisplay()
        }
        updatedData = dataToShow.data;
      }
      
      this.infoMessage = updatedData;

      // console.log('COLOR | message ----- ', this.messageColor, this.infoMessage);
      this.toURL = this.prepareInnerUrl();
      // this.$router.push(this.prepareInnerUrl());
    },
    prepareInnerUrl() {
      return `/supervision/dashboard/${this.userRole.toLowerCase().replace('_', '')}`;
    },
    revertStatusDisplay() {
      // TODO: RESET RADIO BUTTONS
    }
  },
  created () {
    if (this.item.type === 'Finastra') {
      const parsedData = shared.getMidAndExtRef(this.item)
      this.miscId = parsedData?.miscId && parsedData.miscId[0]
      this.externalIdRef = parsedData?.externalIdRef && parsedData.externalIdRef[0]
      this.transactionAmount = shared.parseTransactionAmount(this.item.incomingTransactionFullData)
    } 
  },
};
</script>

<style scoped>
.searched_name {
  color: rgb(196, 0, 0);
  margin-left: 10px;
}
</style>
