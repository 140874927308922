<template>
  <div class="d-flex mx-4 my-n4">
    <input type="file" counter hidden multiple ref="files" @change="listFiles" />
    <v-select
      v-model="files"
      :items="files"
      chips
      outlined
      multiple
      clearable
      flat
      @change="getFiles"
      @click.stop="$refs.files.click()"
      @click:clear="emitClear"
      :label="label"
      persistent-hint
      append-icon=""
    ></v-select>
    <!-- <v-btn class="primary" @click="getFiles">
<v-icon>mdi-cloud-upload</v-icon>
</v-btn> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      file: null,
      files: [],
      label: "Insert up to 3 files, in PDF format only.",
      filesToSend: [],
    };
  },

  props: {
    title: String,
    isMultiple: Boolean,
  },
  methods: {
    listFiles() {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files.push(this.$refs.files.files[i].name);
        this.filesToSend.push(this.$refs.files.files[i]);
      }
      this.getFiles();
    },
    getFiles() {
      // var formData= new FormData()
      if (this.filesToSend.length && this.files.length) {
        this.filesToSend.forEach((file, i) => {
          if (file.type !== "application/pdf") {
            alert(file.name + " is not a PDF format file.");
            this.emitClear();
            return;
          } else if (i > 2) {
            alert("Exceeded file capacity.");
            this.emitClear();
            return;
          }
        });
        // console.log('foremData :>> ', formData);
        this.$emit("getFiles", this.filesToSend);
      }
    },
    emitClear() {
      this.files = [];
      this.filesToSend.length = 0;
      this.$emit("noFiles");
    },
  },
};
</script>
