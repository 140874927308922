<template>
  <v-toolbar-items>
    <v-btn @click="$emit('delete')" :disabled="selected.length === 0">
      <v-icon> delete </v-icon>
      Delete
    </v-btn>
    <v-btn @click="$emit('reset')" :disabled="selected.length === 0">
      <v-icon> clear </v-icon>
      Clear
    </v-btn>
    <v-btn @click="$emit('refresh')" :disabled="selected.length === 0">
      <v-icon> refresh </v-icon>
      Refresh
    </v-btn>
  </v-toolbar-items>
</template>

<script>
export default {
  props: {
    selected: Array,
  },
};
</script>

<style>
</style>
