<template>
  <v-container fluid class="mt-8">
    <v-row no-gutters class="d-flex justify-space-between align-items-center">
      <v-menu
        v-model="showCalendar"
        :close-on-content-click="false"
        transition="scale-transition"
        max-width="270px"
        min-width="290px"
        @input="updateValue"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            label="Choose query start and end date:"
            :value="dateRangeText"
            prepend-icon="event"
            readonly
            v-on="on"
            style="min-width: 200px; max-width: 300px"
            append-icon="close"
            @click:append="resetValue"
            class="pr-3"
          />
        </template>
        <v-date-picker v-model="filter.datesRange" range />
      </v-menu>

      <div class="d-flex justify-space-between">
        <v-switch v-model="filter.showAlerted" label="Alerted" class="ml-2 mr-2" />
        <v-switch v-model="filter.showVerified" label="Verified" class="ml-2 mr-2" />
        <v-switch v-model="filter.showFlagged" label="Flagged" class="ml-2" />
      </div>

      <div align="center" class="mr-5">
        <v-text-field
          label="Show only results containing this text"
          v-model="filter.searchFor"
          clearable
          @input="debouncedSearch"
          autofocus
          class="pr-5"
        />
        <v-btn color="primary" @click="applyFilter">Apply filter</v-btn>
        <v-btn color="secondary" class="ml-2" @click="resetValue">Reset filter</v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { debounce } from "lodash";

export default {
  props: ["value"],
  data() {
    return {
      filter: {
        datesRange: [],
        showEmptyResults: true,
        searchFor: null,
        showAlerted: true,
        showVerified: true,
        showFlagged: true,
      },
      showCalendar: false,
    };
  },
  computed: {
    dateRangeText() {
      return this.filter.datesRange
        .map((d) => this.moment(d).format("DD.MM.YYYY"))
        .join(" \u2014 ");
    },
  },
  methods: {
    updateValue() {
      this.filterToLocalStorage(this.filter);
      this.$emit("input", this.filter);
      //this.$emit('click');
    },
    filterToLocalStorage(filterParams) {
      localStorage.setItem("queryLogFilter", JSON.stringify(filterParams));
    },
    filterFromLocalStorage() {
      if (localStorage.getItem("queryLogFilter")) {
        return JSON.parse(localStorage.getItem("queryLogFilter"));
      }

      return null;
    },
    resetValue() {
      this.filter.datesRange = [
        this.moment().subtract(process.env.VUE_APP_BACK_DAYS_FILTER, "days").toISOString(),
        this.moment().toISOString(),
      ];
      this.filter.showEmptyResults = true;
      this.filter.showAlerted = true;
      this.filter.showVerified = true;
      this.filter.showFlagged = true;
      this.filter.searchFor = null;
      this.filterToLocalStorage(this.filter);
      // this.$emit("input", this.filter);
      this.$emit("click");
    },
    applyFilter() {
      this.filterToLocalStorage(this.filter);
      this.$emit("click");
    },
    debouncedSearch: debounce(
      // eslint-disable-next-line func-names
      function () {
        this.updateValue();
      },
      1000
    ),
  },
  created() {
    const storedFilter = this.filterFromLocalStorage();
    if (storedFilter) {
      this.filter = storedFilter;
    } else {
      this.resetValue();
    }
  },
};
</script>

<style scoped></style>
