<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" outlined :disabled="isSubmitDisabled" class="mt-1">
        Approve
      </v-btn>
    </template>
    <approveForm @close="close" @submit="submitSingle($event)"></approveForm>
  </v-dialog>
</template>

<script>
import ApproveQuerylogsForm from "../../supervision/querylogs/action-forms/ApproveQuerylogsForm";

export default {
  props: {
    isSubmitDisabled: Boolean,
    item: Object,
  },
  data() {
    return {
      dialog: null,
    };
  },
  components: {
    approveForm: ApproveQuerylogsForm,
  },
  methods: {
    submitSingle($event) {
      this.$emit(
        "submit",
        JSON.stringify({
          item: [this.item],
          reason: $event[1],
          files: $event[2],
        })
      );
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style></style>
