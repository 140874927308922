<template>
  <v-toolbar-items>
    <v-container align="center">
      <v-radio-group dense :value="value" :column="column" @change="updateStatus">
        <v-radio
          v-for="s in statuses"
          :label="s"
          :value="s"
          :key="s"
          class="mr-2 ml-2"
          :disabled="disabled"
        />
        <v-btn outlined @click="$emit('input', status)" class="ma-1" :disabled="isDisabled">
          Update status
        </v-btn>
      </v-radio-group>
    </v-container>
  </v-toolbar-items>
</template>

<script>
export default {
  props: {
    value: { type: String },
    column: { type: Boolean },
    disabled: { type: Boolean },
  },
  data() {
    return {
      status: null,
      statuses: ["verified", "flagged"], // ["alerted", "verified", "flagged"]
      isDisabled: true,
    };
  },
  methods: {
    updateStatus(updatedStatus) {
      this.status = updatedStatus;
      this.isDisabled = false;
    },
  },
  mounted() {
    this.status = this.value;
  },
};
</script>
