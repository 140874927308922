import { pick } from "lodash";

const supervisorCrudRequestKeys = [
  "_id",
  "firstname",
  "lastname",
  "username",
  "password",
  "supervisorUserId",
  "managerId",
  "workers",
];
const workerCrudRequestKeys = [
  "id",
  "firstname",
  "lastname",
  "username",
  "password",
  "workerUserId",
  "managerId",
];

export const createCRUDSupervisorRequest = (supervisor) => {
  const query = pick(supervisor, supervisorCrudRequestKeys);

  // if (supervisor.workers) {
  //   query.workers = supervisor.workers.map((worker) =>{
  //     console.log('worker.id :>> ', worker.id);
  //     return worker.id
  //   } );
  // }

  return query;
};

export const createCRUDWorkerRequest = (worker) => {
  const query = pick(worker, workerCrudRequestKeys);
  if (worker.supervisor) {
    query.supervisor = worker.supervisor.id;
  }

  return query;
};

export const createAssignQuerylogsRequest = (selectedQuerylogs, selectedWorker) => ({
  querylogs: selectedQuerylogs,
  worker: selectedWorker || null,
  action: "assign",
});

export const createCloseQuerylogsRequest = (selectedQuerylogs, selectedStatus) => ({
  querylogs: selectedQuerylogs,
  status: selectedStatus,
  action: "close",
});

export const createReopenQuerylogsRequest = (selectedQuerylogs, submitReason, files) => ({
  querylogs: selectedQuerylogs,
  history: {
    message: submitReason,
    time: new Date(Date.now()),
    user: localStorage.getItem("user-name"),
    status: "Reopened",
    files,
  },
  action: "reopen",
});

export const createApproveQuerylogsRequest = (selectedQuerylogs, submitReason, files) => ({
  querylogs: selectedQuerylogs,
  history: {
    message: submitReason,
    time: new Date(Date.now()),
    user: localStorage.getItem("user-name"),
    status: "Closed",
    files,
  },
  action: "approve",
});

export const createSubmitQuerylogsRequest = (selectedQuerylogs, submitReason, files) => ({
  querylogs: selectedQuerylogs,
  history: {
    message: submitReason,
    time: new Date(Date.now()),
    user: localStorage.getItem("user-name"),
    status: "Submitted",
    files,
  },
  action: "submit",
});

export const createSubmitWithStatusQuerylogsRequest = (selectedQuerylogs, submitReason, files, status) => ({
  querylogs: selectedQuerylogs,
  history: {
    message: submitReason,
    time: new Date(Date.now()),
    user: localStorage.getItem("user-name"),
    status: "Submitted",
    finalStatus: status,
    files,
  },
  action: "submit",
});

export const createDeleteQuerylogsRequest = (selectedQuerylogs) => ({
  querylogs: selectedQuerylogs,
  action: "delete",
});
