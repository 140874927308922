<template>
  <div>
    <div v-for="(searchedField, index) in item.searchedFields" :key="index">
      <v-card variant="tonal" class="mb-4" v-if="searchedField.numberOfResults">
        <v-card-title class="pb-0" v-if="searchedField.searchedNameRole">
          <v-icon large left> account_circle </v-icon>
          <span>{{ searchedField.searchedNameRole }}</span>
          <span style="display: inline-block; padding-top:5px; font-weight: normal;margin-left: 20px;font-size: 1rem;color: #161A4F;">
            ({{ searchedField.searchedName }})
          </span>
          <span v-if="item.type == 'Finastra'" :class="searchedField.status" style="display: inline-block; padding-top:5px; font-weight: normal;margin-left: 20px;font-size: 1rem;">
            {{ searchedField.status }} 
          </span>
        </v-card-title>
        <v-card-text class="mt-0 pt-0">
          <EntitiesList :items="searchedField.results" :headers="headers" dense class="mt-5" />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import EntitiesList from "@/components/entity/EntitiesList";

export default {
  components: {
    EntitiesList,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "foundResultName", width: "50%" },
        { text: "Id", value: "uniqueEntityId", width: "10%" },
        { text: "Category", value: "category", width: "10%" },
        { text: "Score (%)", value: "max_score", width: "10%" , align: 'right'},
        { text: "Data source", value: "sourceId", width: "20%" },
      ],
    };
  },
  methods: {
    extractNames() {
      this.item.searchedFields.forEach((searchedField) => {
        if (searchedField.results) {
          searchedField.results.forEach((result) => {
            result.foundResultName = result.foundNames.map((name) => name.name);
          });
        } else {
          searchedField.results = [];
        }
      });
    },
  },
  created() {
    this.extractNames();
  },
};
</script>

<style scoped>
  .verified{
    color: green;
  }
  .alerted{
    color: orange;
  }
  .flagged{
    color: red;
  }
</style>
