<template>
  <v-snackbar   
    tile
    :value="value"
    @input="$emit('input', false)"
    :timeout="timeDelay"
    :color="color"
    type="info"
    elevation="24"
  >
    <v-icon>info</v-icon>
      {{ showMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="closeNotification">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: Boolean,
    timeDelay: Number,
    isUpdateSuccess: Boolean,
    message: String,
    color: String,
    toURL: {
      type: String,
      default: ''
    }
  },
  data: () => ({
      snackbar: false
    }),
  computed: {
    setColor() {
      return !this.message.includes('Error') ? 'success' : 'error';
    },
    showMessage() {
      if (this.message) {
        return this.message;
      }
      return this.isUpdateSuccess ? 'Status was updated' : 'Error - status was not updated';
    },
  },
  methods: {
    closeNotification(){
      if (this.toURL) {
        this.$router.push(this.toURL);
      }
      this.$emit('input', false);
    }
  }
};
</script>

<style>
</style>
