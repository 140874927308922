<template>
  <v-container fluid>
    <QueryLogFilter v-model="filterParams" @click="onFilterInput" />
    <v-divider />
    <!-- TODO: HERE IS LOAD INDICATOR -->
    <LoadIndicator v-if="loading" v-model="isLoading" />

    <v-row v-if="items.length" class="mt-1 mb-5">
      <v-toolbar flat>
        <UpdateStatusToolbar
          v-if="userRole !== 'SYS_ADMIN'"
          @input="updateStatus"
          :disabled="selectedLogs.length === 0"
        />
        <v-spacer />
        <!-- TODO: (Ariel 2022-12-27) REMOVE FALSE FROM v-if AFTER ACTION BUTTONS FIXED -->
        <ActionsToolbar
          v-if="isUserSystemAdmin && false"
          :selected="selectedLogs"
          @delete="deleteItems"
          @reset="resetItems"
          @refresh="refreshItems"
        />
      </v-toolbar>
      <UpdateStatusNotification
        v-model="updateStatusRequested"
        :isUpdateSuccess="isUpdateStatusSuccess"
      />
      <!--
      <progress-bar v-if="loading"></progress-bar>
      -->
      <QueryLogList
        v-model="selectedLogs"
        :items="items[0]"
        @reloadData="loadData"
        @loading="loading"
        :backPath="currentPath"
      />
    </v-row>
    <v-row v-else-if="!loading" justify="center" class="pt-3">
      <NoResultsMessage />
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { createQueryLogRequest } from "@/helpers/querylog";
import LoadIndicator from "@/components/common/LoadIndicator";
import QueryLogFilter from "@/components/query-log/QueryLogFilter";
import ActionsToolbar from "@/components/query-log/toolbars/ActionsButtons";
import UpdateStatusToolbar from "@/components/query-log/toolbars/UpdateStatus";
import UpdateStatusNotification from "@/components/query-log/UpdateStatusNotification";
import QueryLogList from "@/components/query-log/QueryLogList";
import NoResultsMessage from "@/components/common/NoResultsMessage";

import {
  QUERY_LOG_DELETE_REQUEST,
  QUERY_LOG_RESET_REQUEST,
  QUERY_LOG_REFRESH_REQUEST,
  QUERY_LOG_UPDATE_REQUEST,
  QUERY_LOG_INIT,
  QUERY_LOG_REQUEST,
} from "@/store/actions/queryLog";

const { mapActions, mapGetters } = createNamespacedHelpers("queryLog");

export default {
  components: {
    QueryLogFilter,
    LoadIndicator,
    ActionsToolbar,
    UpdateStatusToolbar,
    UpdateStatusNotification,
    QueryLogList,
    NoResultsMessage,
    // ProgressBar,
  },
  data() {
    return {
      filterParams: {},
      selectedLogs: [],
      updateStatusRequested: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      items: "result",
      hasLoadedOnce: "hasLoadedOnce",
      updateStatusResult: "status",
      userRole: "userRole",
      isLoading: "isLoading",
    }),
    isUpdateStatusSuccess() {
      return this.updateStatusResult === "success";
    },
    isUserSystemAdmin() {
      return this.userRole === "ADMIN";
    },
    currentPath() {
      return this.$router.currentRoute.path;
    },
  },
  methods: {
    ...mapActions({
      init: QUERY_LOG_INIT,
      getQueryLog: QUERY_LOG_REQUEST,
      delete: QUERY_LOG_DELETE_REQUEST,
      reset: QUERY_LOG_RESET_REQUEST,
      refresh: QUERY_LOG_REFRESH_REQUEST,
      update: QUERY_LOG_UPDATE_REQUEST,
    }),
    async onFilterInput() {
      this.init();
      this.loading = true;
      await this.loadData();
    },
    async deleteItems() {
      await this.delete(this.selectedLogs);
    },
    async resetItems() {
      await this.reset(this.selectedLogs);
    },
    async refreshItems() {
      await this.refresh(this.selectedLogs);
    },
    async updateStatus(newStatus) {
      if (newStatus && this.selectedLogs?.length) {
        await this.update({ items: this.selectedLogs, status: newStatus });
        this.updateStatusRequested = true;
      }
    },
    async loadData(childRequest = null) {
      this.loading = true;
      // console.log('childRequest', childRequest);
      if (childRequest?.itemsPerPage == -1) {
        childRequest = JSON.parse(JSON.stringify(childRequest)); // to keep the rows per page showing 'All'
        childRequest.itemsPerPage = childRequest.total;
      }

      this.filterParams = JSON.parse(localStorage.getItem("queryLogFilter"));
      const request = createQueryLogRequest(this.filterParams, this.items, childRequest);
      await this.getQueryLog(request);
      this.loading = false;
    },
  },
  async created() {
    window.onpopstate = function () {
      location.reload();
    };
    await this.loadData();

    // console.log(this.$router.currentRoute.path);
  },
};
</script>
