<template>
  <v-overlay v-model="value">
    <v-progress-circular size="70" width="7" color="purple" indeterminate />
  </v-overlay>
</template>
<script>
export default {
  name: 'LoadIndicator',
  props: ['value'],
};
</script>
