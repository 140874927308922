<template>
  <v-container fluid>
    <v-snackbar v-model="pop">
      There are {{ total }} querylogs, loading might take some time.
      <template v-slot:action="{ attrs }">
        <v-btn color="blue darken-5" text v-bind="attrs" @click="pop = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-row class="d-flex justify-center">
      <v-col cols="3">
        <v-select
          v-if="options.groupBy"
          label="Group by"
          v-model="options.groupBy[0]"
          :items="cutDownHeaders"
          clearable
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :value="selected"
          @input="$emit('input', $event)"
          :headers="headers"
          :items="items.data"
          dense
          item-key="_id"
          @click:row="toDetails"
          :show-select="true"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [10, 20, 50, 1000] }"
        >
          <template v-slot:item.time="{ item }">
            <span>{{ fmt(item.time) }}</span>
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <span>{{ fmt(item.updatedAt) }}</span>
          </template>
          <template v-slot:group.header="{ group, headers, toggle, isOpen }">
            <td v-if="options.groupBy[0]" :colspan="headers.length">
              <v-btn @click="toggle" x-small icon :ref="group">
                <v-icon v-if="isOpen">mdi-minus</v-icon>
                <v-icon v-else>mdi-plus</v-icon>
              </v-btn>
              <span class="mx-5 font-weight-bold">{{ group }}</span>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { format } from "@/utils/dates";
import { QUERY_LOG_FETCH_ITEM_REQUEST } from "@/store/actions/queryLog";

const { mapActions, mapGetters } = createNamespacedHelpers("queryLog");

export default {
  props: {
    value: Array,
    items: Object,
    loading: Boolean,
  },
  data() {
    return {
      total: 0,
      options: {},
      pop: false,
      timeoutInterval: null,
      cutDownHeaders: [
        {
          text: "Status",
          value: "status",
        },
        // {
        //   text: "Searched name",
        //   value: "searchedName",
        // },
        {
          text: "Type",
          value: "type",
        },
        // {
        //   text: "Results found",
        //   value: "totalNumberOfResults",
        // },
        // {
        //   text: "Searcher",
        //   value: "username",
        // },
      ],
      headers: [
        {
          text: "Status",
          value: "status",
          groupable: true,
          align: "center",
        },
        {
          text: "Searched name",
          value: "searchedName",
          align: "center",
          groupable: true,
        },
        {
          text: "Query Id",
          value: "_id",
          align: "center",
          groupable: true,
        },
        {
          text: "Time of search",
          value: "time",
          align: "center",
          groupable: true,
        },
        {
          text: "Last update",
          value: "updatedAt",
          align: "center",
          groupable: true,
        },
        {
          text: "Type",
          value: "type",
          align: "center",
          groupable: true,
        },
        {
          text: "Results found",
          value: "totalNumberOfResults",
          align: "center",
          groupable: true,
        },
        {
          text: "Searcher",
          value: "username",
          align: "center",
          groupable: true,
        },
      ],
      selected: [],
      pageSize: 10,
      infiniteId: 0,
    };
  },
  computed: {
    ...mapGetters({
      noMoreToFetch: "noMore",
    }),
    scrollingOn() {
      return this.items && this.items.length;
    },
  },
  methods: {
    ...mapActions({
      fetchItem: QUERY_LOG_FETCH_ITEM_REQUEST,
    }),
    async toDetails(item) {
      await this.fetchItem(item);
      try {
        await this.$router.push({ name: "queryLogDetails", params: { item, id: item._id } });
      } catch (e) {
        // do nothing.
        // TODO: need to rework this
      }
    },

    async infiniteHandler($state) {
      if (this.noMoreToFetch) {
        $state.complete();
        return;
      }

      // create a request to QueryLog component to fetch more data and await the request
      const loadingDataPromise = new Promise((resolve) => {
        this.$emit("scroll-table", { pageSize: this.pageSize, resolve });
      });
      await loadingDataPromise;

      this.infiniteId += 1;
      $state.loaded();
    },
    fmt(time) {
      return format(time);
    },
    extractNames() {
      this.items.data.forEach((item) => {
        item.searchedFields.forEach((searchedField) => {
          if (searchedField.results) {
            searchedField.results.forEach((result) => {
              result.foundResultName = result.foundNames.map((name) => name.name);
            });
          }
        });
      });
    },
  },
  mounted() {
    this.extractNames();
    // console.log('QUERY LOG LIST ========== ', this.items);
    this.selected = this.value;
    this.loading = false;
    this.total = this.items?.metadata ? this.items.metadata.total : 0;
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (
          newVal.itemsPerPage !== oldVal.itemsPerPage ||
          newVal.page !== oldVal.page ||
          newVal.sortBy[0] !== oldVal.sortBy[0] ||
          newVal.sortDesc[0] !== oldVal.sortDesc[0]
        ) {
          if (newVal.itemsPerPage === -1) {
            newVal.total = this.items.metadata.total;
          }
          if (this.items.metadata.total > 5) {
            this.pop = true;
            if (this.timeoutInterval) {
              clearTimeout(this.timeoutInterval);
            }
            this.timeoutInterval = setTimeout(() => {
              this.pop = false;
            }, 2500);
          }
          this.$emit("reloadData", this.options);
        }
      },
    },
    deep: true,
  },
};
</script>
