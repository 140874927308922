import { parseString } from 'xml2js';
import {format} from "./dates"
import { autoClosed , Labels } from '../helpers/dataLabels';

function getAnalystSupervisor(obj) {
  if (obj.workerId?.username) {
    return obj.workerId.username
  } else if (obj.supervisorId?.username) {
    return obj.supervisorId.username
  } 
  
  return ""
}

function getAlertedFields(obj) {
  const stringList = []
  for (const field of obj.searchedFields) {
    if (field.numberOfResults) {
      stringList.push(field.searchedNameRole)
    }
  }

  if (obj.sanctionedItemsSearch?.length) {
    for (const sanction of obj.sanctionedItemsSearch) {
      if (sanction.paymentSegmentField) {
        stringList.push(sanction.paymentSegmentField)
      }
    }
  }
  return stringList.join('; ')
}

export default {
  capitalize: function (str) {
    return str[0].toUpperCase() + str.substr(1);
  },
  currentDate: function (withTime = null) {
    const today = new Date(Date.now());
    const time = withTime ? `_${today.getHours()}_${today.getMinutes()}` : "";
    return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}${time}`;
  },
  getMidAndExtRef(item) {
    let miscId = ''
    let externalIdRef = ''
    const incomingTransactionFullData = item?.incomingTransactionFullData
    if (incomingTransactionFullData && incomingTransactionFullData.Payload) {
      if (incomingTransactionFullData.Payload) {
        parseString(incomingTransactionFullData.Payload,  (err, result) => {
          if (err) return ''
          const PaymentSegment = result['ns2:FrdReq']?.PaymentSegment
          if (PaymentSegment.length) {
            miscId = PaymentSegment[0].MiscNotes 
            externalIdRef = PaymentSegment[0].ExtRef 
          }
        });
      } 
    }
    return {miscId , externalIdRef}
  },
  parseTransactionAmount(incomingTransactionFullData) {
    let paymentAmount = ''
    parseString(incomingTransactionFullData.Payload,  (err, result) => {
      const paymentSegment = result && result['ns2:FrdReq'].PaymentSegment
      if (paymentSegment && paymentSegment.length) {
        if (paymentSegment[0].PaymentAmount) {
          paymentAmount = `${paymentSegment[0].PaymentAmount[0].Amount[0]} ${paymentSegment[0].PaymentAmount[0].Currency[0]}`
        } 
      }
    });
    return paymentAmount
  },
  generateCSVContent(list) {
    const headers = [ 'Query Date','ExtRef', 'MID', 'Amount', 'Alerted', 'Last Update', 'Status', 'Analyst Status', 'Analyst / Supervisor', 'AlertedFields'];

    const rows = [headers];
  
    list.forEach(obj => {
      if (obj.type !== 'AML') {
        const time = format(obj.time);
        const analystSupervisor = getAnalystSupervisor(obj);
        const lastUpdate = format(obj.updatedAt);
        const amount = obj.incomingTransactionFullData ? this.parseTransactionAmount(obj.incomingTransactionFullData) : 'No data';
        const alerted = obj.totalNumberOfResults > 0 ? 'Yes' : 'No';
        const alertedFields = getAlertedFields(obj);
        const midAndExt = this.getMidAndExtRef(obj);
        const midNum = midAndExt.miscId ? midAndExt.miscId[0] : '';
        const extRef = midAndExt.externalIdRef ? midAndExt.externalIdRef[0] : '';
        const finalStatus = this.isAutoClosed(obj) ? autoClosed : obj.finalStatus;

        rows.push([time, extRef ,midNum, amount, alerted, lastUpdate, obj.status, finalStatus, analystSupervisor, alertedFields]);
      }

    });  
    return rows.map(row => row.join(',')).join('\n');
  },
  downloadCSV(filename, csvContent) {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  },
  timeframeString(time_range) {
    if (time_range >= 60) {
      return `${ time_range / 60} hours`;
    } else {
      return `${ time_range } minutes`;
    }
  },
  isAutoClosed(querylog) {
    return querylog.finalStatus === Labels.CLOSED && !querylog.supervisorId;

  }
};

