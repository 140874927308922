<template>
  <v-form @submit.prevent="$emit('submit', [data.selectedQuerylogs, reopenReason, submittedfiles])">
    <v-card>
      <v-card-title> Are you sure you want to reopen the querylogs? </v-card-title>
      <v-card-text>
        <v-text-field label="Reason for reopening" v-model="reopenReason" />
      </v-card-text>
      <file-input-multiple @getFiles="getFiles" @noFiles="noFiles"></file-input-multiple>
      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="primary" type="submit"> Submit </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import fileInputMultiple from "./file-input-multiple.vue";
export default {
  components: { fileInputMultiple },
  data() {
    return {
      reopenReason: "",
      submittedfiles: [],
    };
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        selectedQuerylogs: [],
      }),
    },
  },
  methods: {
    noFiles() {
      this.submittedfiles = [];
      this.$emit("noFiles");
    },
    getFiles(files) {
      this.submittedfiles = files;
    },
  },
};
</script>
